import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

// import favicon from '../../images/favicon.png';

import './layout.scss'

const Layout = ({ className = "", contentClassName = "", children, meta = {}, shareImage, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const newTitle = title || site.siteMetadata.title
  const description = site.siteMetadata.description
  const image = shareImage || 'https://www.tiv.co/share-graphic2.png';

  return (

    <div className={`Layout ${className}`}>
      <Helmet 
        title={newTitle}
        meta={[
          {
            name: `viewport`,
            content: "width=device-width, initial-scale=1.0, viewport-fit=cover",
          },
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: newTitle,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: image,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: "@tock",
          },
          {
            name: `twitter:title`,
            content: newTitle,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: `twitter:image`,
            content: image,
          },
        ].concat(meta)}
      >
        <html lang="en" />
        <head>
        </head>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TF81JB23X9" />
        <script src={'/ga.js'} />
        <script src={'/iubenda.js'} />
        <script src={'/prefinery.js'} />
        <script src="https://widget.prefinery.com/widget/v2/yxzd8fjx.js" defer></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js" />
        <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async />
     </Helmet>
      <div className={`Layout-content ${contentClassName}`}>{children}</div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  meta: PropTypes.any,
  shareImage: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default Layout